<template>
    <DefaultModal
      :title="$t('create_self_service_template')"
      :submit-button-text="'create'"
      :hide-save-button="disableForm"
      :save-button-disabled="modalSaveButtonDisabled"
      @submit="create"
      ref="modal"
    >
      <template v-slot:content>
        <v-text-field
          :label="$t('name')"
          :autofocus="true"
          :rules="[
            v => !!v || $t('form_rules.mandatory'),
            v => /^([a-zA-Z0-9À-ž\s\._-]+)$/igu.test(v) || $t('form_rules.invalid_filename'),
          ]"
          :disabled="disableForm"
          :error-messages="errorMessage"
          @clearInput="resetNameValidation()"
          @change.native="validateName($event.target.value)"
          name="name"
          outlined
          dense
        >
        </v-text-field>
        <UploadField 
          ref="templateUpload" 
          :rules="[v => (v && v.length > 0) || $t('form_rules.mandatory')]"
          :multiple="false"
          accept=".zip"
        />
      </template>
    </DefaultModal>
  </template>
  
  <style scoped>
    .exclamation-icon {
      margin-left: -15px;
    }
  </style>
  
  <script>
  
  import { mapActions } from 'vuex'
  
  import DefaultModal from '@/components/DefaultModal'
  
  import AppActions from '@/store/app/actions-types'
  import SelfServiceTemplateActions from '@/store/content/self_service_template/actions-types'
  import EntryActions from '@/store/content/entry/actions-types'
  import UploadField from '@/components/content/default/Form/UploadField.vue'

  export default {
    name: 'CreateSelfServiceTemplateModal',
  
    components: {
      DefaultModal,
      UploadField
    },
  
    data: () => ({
      error: false,
      errorMessage: "",
      modalSaveButtonDisabled: true
    }),
  
    computed: {
      disableForm() {
        return this.error
      },
    },
  
    methods: {
      ...mapActions('app', [
        AppActions.OPEN_APP_INFO_MESSAGE,
        AppActions.OPEN_APP_ERROR_MESSSAGE
      ]),
  
      ...mapActions('content/self_service_template', [
        SelfServiceTemplateActions.CREATE_SELF_SERVICE_TEMPLATE,
      ]),
  
      ...mapActions('content/entry', [
        EntryActions.CHECK_ENTRY_NAME_EXISTS
      ]),
  
      open() {
        this.error = false
        this.resetNameValidation()
  
        this.$refs.modal.openModal()
      },
  
      close() {
        this.$refs.modal.closeModal()
      },
  
      resetNameValidation() {
        this.errorMessage = ""
        this.modalSaveButtonDisabled = true
      },
  
      validateName(name) {
        this.resetNameValidation()
  
        const parentId = this.$route.params.parentId
        const extension = 'jst'
        const paths = [`${name}.${extension}`]
  
        this.isExistentEntry(parentId, { paths }).then(res => {
          if(!res) {
            return
          }
  
          this.errorMessage = this.$t('entry_already_exists', {entry: res.already_exists.join(", ")})
        })
      },
  
      isExistentEntry(nodeId, data) {
        return this[EntryActions.CHECK_ENTRY_NAME_EXISTS]({nodeId, data})
          .then(res => {
            if(!res.already_exists.length) {
              this.modalSaveButtonDisabled = false
              return
            }
  
            this.modalSaveButtonDisabled = true
  
            return res
          })
      },
  
      create(formData) {
        const folder_parent_id = this.$route.params.parentId
        const name = formData.get('name')
        const files = this.$refs.templateUpload.getFiles()
        const file = files[0]

        this[SelfServiceTemplateActions.CREATE_SELF_SERVICE_TEMPLATE]({nodeId: folder_parent_id, name: name, file: file})
          .then(() => {
            this[AppActions.OPEN_APP_INFO_MESSAGE](this.$t('self_service_template_created'))
    
            this.$refs.modal.closeModal()
            this.$emit('createdSelfServiceTemplate')
          })
          .catch((response) => {
            this.error = true
            this.$refs.modal.closeModal()
            this[AppActions.OPEN_APP_ERROR_MESSAGE](this.$t('self_service_template_creation_error') + ` ${response}`)
          })
      }
    },
  }
  
  </script>
  