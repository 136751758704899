<template>
  <DefaultModal
    :title="$t('create_gdoc')"
    :submit-button-text="'create'"
    :hide-save-button="disableForm"
    :save-button-disabled="modalSaveButtonDisabled"
    @submit="createGDoc"
    ref="modal"
  >
    <template v-slot:content>
      <v-card
        v-if="error"
        tile
        flat
      >
        <v-card-text align="center">
          <v-icon
            color="defaultIconColor"
            large
          >mdi-google-drive</v-icon>
          <v-icon
            class="exclamation-icon"
            color="error"
            large
            absolute
          >mdi-exclamation-thick</v-icon>
        </v-card-text>

        <v-card-subtitle
          align="center"
        >{{ $t('gdrive_config_not_working') }}</v-card-subtitle>
      </v-card>

      <v-text-field
        v-else
        :label="$t('name')"
        :autofocus="true"
        :rules="[
          v => !!v || $t('form_rules.mandatory'),
          v => /^([a-zA-Z0-9À-ž\s\._-]+)$/igu.test(v) || $t('form_rules.invalid_filename'),
        ]"
        :disabled="disableForm"
        :error-messages="errorMessage"
        @clearInput="resetNameValidation()"
        @change.native="validateGdocsName($event.target.value)"
        name="name"
        outlined
        dense
      >
        <v-icon
          v-if="gDocMimeType"
          slot="prepend"
          :color="icon.color || 'defaultIconColor'"
        >{{ icon.icon }}</v-icon>
      </v-text-field>
    </template>
  </DefaultModal>
</template>

<style scoped>
  .exclamation-icon {
    margin-left: -15px;
  }
</style>

<script>

import { mapActions, mapState } from 'vuex'
import router from '@/router'

import IconsByMimetype from '@/utils/iconsByMimetype.js'

import DefaultModal from '@/components/DefaultModal'

import AppActions from '@/store/app/actions-types'
import AuthActions from '@/store/core/auth/actions-types'
import UserActions from '@/store/core/user/actions-types'
import ContentActions from '@/store/content/actions/actions-types'
import EntryActions from '@/store/content/entry/actions-types'
import UpdateBrowserTab from '@/utils/updateBrowserTab'

export default {
  name: 'CreateGDocModal',

  components: {
    DefaultModal,
  },

  data: () => ({
    error: false,
    errorMessage: "",
    modalSaveButtonDisabled: true,
    userHasServiceAccount: false,
    gDocMimeType: null,
    extensionsByMimetype: {
      "application/vnd.google-apps.document": 'gdocs',
      "application/vnd.google-apps.spreadsheet": 'gsheets',
      "application/vnd.google-apps.presentation": 'gpres',
    }
  }),

  computed: {
    ...mapState({
      userData: state => state.core.auth.userData,
    }),

    icon() {
      return IconsByMimetype.getIconByMimetype(this.gDocMimeType)
    },

    disableForm() {
      return this.error || ! this.userHasServiceAccount
    },
  },

  methods: {
    ...mapActions('core/auth', [
      AuthActions.RENEW_TOKEN,
    ]),

    ...mapActions('app', [
      AppActions.OPEN_APP_INFO_MESSAGE,
      AppActions.OPEN_APP_SUCCESS_MESSAGE,
      AppActions.OPEN_APP_ERROR_MESSAGE,
    ]),

    ...mapActions('core/user', [
      UserActions.UPDATE_USER,
      UserActions.ATTACH_GOOGLE_ACCOUNT
    ]),

    ...mapActions('content/actions', [
      ContentActions.CREATE_GDOC,
    ]),

    ...mapActions('content/entry', [
      EntryActions.CHECK_ENTRY_NAME_EXISTS
    ]),

    open(mimeType) {
      this.gDocMimeType = mimeType
      this.error = false
      this.userHasServiceAccount = this.checkUserServiceAccount()
      this.resetNameValidation()

      if (! this.userHasServiceAccount) {
        this.googleLogin()
      }

      this.$refs.modal.openModal()
    },

    close() {
      this.$refs.modal.closeModal()
    },

    resetNameValidation() {
      this.errorMessage = ""
      this.modalSaveButtonDisabled = true
    },

    validateGdocsName(name) {
      this.resetNameValidation()

      const parentId = this.$route.params.parentId
      const extension = this.extensionsByMimetype[this.gDocMimeType]
      const paths = [`${name}.${extension}`]

      this.isExistentEntry(parentId, { paths }).then(res => {
        if(!res) {
          return
        }

        this.errorMessage = this.$t('entry_already_exists', {entry: res.already_exists.join(", ")})
      })
    },

    isExistentEntry(nodeId, data) {
      return this[EntryActions.CHECK_ENTRY_NAME_EXISTS]({nodeId, data})
        .then(res => {
          if(!res.already_exists.length) {
            this.modalSaveButtonDisabled = false
            return
          }

          this.modalSaveButtonDisabled = true

          return res
        })
    },

    createGDoc(formData) {
      const folder_parent_id = this.$route.params.parentId
      const name = formData.get('name')

      this[ContentActions.CREATE_GDOC]({folder_parent_id, name, mime_type: this.gDocMimeType})
        .then((nodeData) => {
          this[AppActions.OPEN_APP_INFO_MESSAGE](this.$t('gdoc_created'))

          let routeData = router.resolve({name: 'EditGDoc',  params: { appId: this.$route.params.appId, nodeId: nodeData.node_id }, query: { from: this.$route.fullPath } });
          let newWindow = window.open(routeData.href, '_blank');
          let icon = IconsByMimetype.getIconByMimetype(this.gDocMimeType).icon
          newWindow.addEventListener('load', () => {
            UpdateBrowserTab.update(newWindow, name, icon)
          });

          this.$refs.modal.closeModal()
          this.$emit('createdGDdoc')
        })
        .catch(() => {
          this.error = true
          this.$refs.modal.submitting = false
        })
    },

    checkUserServiceAccount() {
      return this.userData.external_accounts.some(ea => ea.service == "google.drive")
    },

    googleLogin() {
      if (!this.$google.apiInitied) {
        this[AppActions.OPEN_APP_ERROR_MESSAGE](this.$t('Google is not initied'))

        return
      }

      this.$google.login()
        .then(() => {
          this.$google.api.client.drive.about.get({'fields': 'user'})
            .then((response) => {
              let email = response.result.user.emailAddress
              let usersId = this.userData.users_id

              this[UserActions.ATTACH_GOOGLE_ACCOUNT]({email, usersId})
                .then(() => {
                  this[AuthActions.RENEW_TOKEN]()
                    .then(() => {
                      this.userHasServiceAccount = true

                      this[AppActions.OPEN_APP_SUCCESS_MESSAGE](this.$t('Success on attach Google Account'))
                    })
                })
            })
            .catch(() => {
              this[AppActions.OPEN_APP_ERROR_MESSAGE](this.$t('Unable to retrieve Drive'))
            })
        })
        .catch(() => {
          this[AppActions.OPEN_APP_ERROR_MESSAGE](this.$t('Unable to login on Google'))
        })
    },
  },
}

</script>
