<template>

  <NavigationContainer
    @go-to-settings="goToSettings"
    :breadcrumbsItems="breadcrumbs"
    :actions="actions"
  >

    <template slot="content">
      <v-row>
        <v-col
          v-for="item in repositories"
          :key="item.repository_id"
          class="d-flex child-flex py-2 px-2"
          xl="2"
          lg="3"
          md="4"
          sm="6"
          cols="12"
          height="200"
        >
          <RepositoryCard 
            :data="item"
            @click-thumb="navigateTo"
          />
        </v-col>
      </v-row>
    </template>

  </NavigationContainer>

</template>

<style scoped>
  .container {
    padding: 0;
  }
</style>

<script>

import { mapState, mapActions } from 'vuex'
import router from "@/router"

import UserRoles from '@/store/core/user/user-roles'

import NavigationContainer from '@/components/content/NavigationContainer.vue'
import RepositoryCard from '@/components/content/RepositoryCard.vue'

import RepositoryActions from '@/store/content/repository/actions-types'

export default {
  name: "ContentHome",

  components: {
    NavigationContainer,
    RepositoryCard,
  },

  data() {
    return {
      options: {},
    }
  },

  mounted() {
    this.fetchData()
  },

  watch: {
    options: {
      handler () {
        this.fetchData()
      },
      deep: true,
    },
  },

  computed: {
    ...mapState({
      userRoles: state => state.core.auth.userRoles,
      repositories: state => state.content.repository.repositories,
    }),

    actions() {
      let actions = []

      if (this.userRoles.includes(UserRoles.USER_ADMIN)) {
        actions.push({
          text: "content_settings",
          eventToEmit: "go-to-settings",
        })
      }

      return actions
    },

    breadcrumbs() {
      return [
        {
          prependIcon: 'mdi-home-outline',
        },
      ]
    },
  },

  methods: {
    ...mapActions('content/repository', [
      RepositoryActions.GET_ALL_REPOSITORIES,
    ]),

    goToSettings() {
      router.push({
        name: 'ContentSettings',
        params: { appId: this.$route.params.appId }
      })
    },

    fetchData() {
      let options = {...this.$route.query}

      options = {
        ...options,
        'filter[active]' : "true"
      }

      this.loading = true

      this.[RepositoryActions.GET_ALL_REPOSITORIES](options)
        .then((result) => {
          this.itemsLength = result.total

          this.loading = false
        })
        .catch(() => this.loading = false)
    },

    navigateTo(item) {
      router.push({
        name: 'ContentNavigation',
        params: { parentId: item.node_id }
      })
    }
  }
}

</script>
