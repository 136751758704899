<template>
  <DefaultModal
    :title="$t('create_synthetic_media')"
    :submit-button-text="'create'"
    :hide-save-button="disableForm"
    :save-button-disabled="modalSaveButtonDisabled"
    @submit="create"
    ref="modal"
  >
    <template v-slot:content>
      <v-text-field
        :label="$t('name')"
        :autofocus="true"
        :rules="[
          v => !!v || $t('form_rules.mandatory'),
          v => /^([a-zA-Z0-9À-ž\s\._-]+)$/igu.test(v) || $t('form_rules.invalid_filename'),
        ]"
        :disabled="disableForm"
        :error-messages="errorMessage"
        @clearInput="resetNameValidation()"
        @change.native="validateName($event.target.value)"
        name="name"
        outlined
        dense
      >
      </v-text-field>
    </template>
  </DefaultModal>
</template>

<style scoped>
  .exclamation-icon {
    margin-left: -15px;
  }
</style>

<script>

import { mapActions } from 'vuex'
import router from '@/router'

import DefaultModal from '@/components/DefaultModal'

import AppActions from '@/store/app/actions-types'
import SyntheticActions from '@/store/content/synthetic/actions-types'
import EntryActions from '@/store/content/entry/actions-types'
import UpdateBrowserTab from '@/utils/updateBrowserTab'

export default {
  name: 'CreateSyntheticMediaModal',

  components: {
    DefaultModal,
  },

  data: () => ({
    error: false,
    errorMessage: "",
    modalSaveButtonDisabled: true
  }),

  computed: {
    disableForm() {
      return this.error
    },
  },

  methods: {
    ...mapActions('app', [
      AppActions.OPEN_APP_INFO_MESSAGE
    ]),

    ...mapActions('content/synthetic', [
      SyntheticActions.CREATE_SYNTHETIC_MEDIA,
    ]),

    ...mapActions('content/entry', [
      EntryActions.CHECK_ENTRY_NAME_EXISTS
    ]),

    open() {
      this.error = false
      this.resetNameValidation()

      this.$refs.modal.openModal()
    },

    close() {
      this.$refs.modal.closeModal()
    },

    resetNameValidation() {
      this.errorMessage = ""
      this.modalSaveButtonDisabled = true
    },

    validateName(name) {
      this.resetNameValidation()

      const parentId = this.$route.params.parentId
      const extension = 'jsm'
      const paths = [`${name}.${extension}`]

      this.isExistentEntry(parentId, { paths }).then(res => {
        if(!res) {
          return
        }

        this.errorMessage = this.$t('entry_already_exists', {entry: res.already_exists.join(", ")})
      })
    },

    isExistentEntry(nodeId, data) {
      return this[EntryActions.CHECK_ENTRY_NAME_EXISTS]({nodeId, data})
        .then(res => {
          if(!res.already_exists.length) {
            this.modalSaveButtonDisabled = false
            return
          }

          this.modalSaveButtonDisabled = true

          return res
        })
    },

    create(formData) {
      const folder_parent_id = this.$route.params.parentId
      const name = formData.get('name')

      this[SyntheticActions.CREATE_SYNTHETIC_MEDIA]({nodeId: folder_parent_id, name: name})
        .then((nodeId) => {
          this[AppActions.OPEN_APP_INFO_MESSAGE](this.$t('synthetic_media_created'))

          let routeData = router.resolve({name: 'EditSyntheticMedia',  params: { appId: this.$route.params.appId, nodeId: nodeId }});
          let newWindow = window.open(routeData.href, '_blank');
          newWindow.addEventListener('load', () => {
            UpdateBrowserTab.update(newWindow, name, 'mdi-star-four-points-box')
          });

          this.$refs.modal.closeModal()
          this.$emit('createdSyntheticMedia')
        })
        .catch(() => {
          this.error = true
          this.$refs.modal.submitting = false
        })
    }
  },
}

</script>
